<template>
    <div class="confirm-mobile">
        <div class="mobile-img">
            <svg @click="$router.push('/Login').catch(() => {})" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                <g id="Group_2278" data-name="Group 2278" transform="translate(-131 -108)">
                    <g id="Rectangle_1140" data-name="Rectangle 1140" transform="translate(131 108)" fill="#fff" stroke="#707070" stroke-width="0.5">
                    <rect width="34" height="34" rx="4" stroke="none"/>
                    <rect x="0.25" y="0.25" width="33.5" height="33.5" rx="3.75" fill="none"/>
                    </g>
                    <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M13.662,12.972l5.295-5.127a.944.944,0,0,0,0-1.369,1.027,1.027,0,0,0-1.416,0l-6,5.809a.946.946,0,0,0-.029,1.336l6.024,5.85a1.029,1.029,0,0,0,1.416,0,.944.944,0,0,0,0-1.369Z" transform="translate(132.749 111.806)"/>
                </g>
            </svg>
            <img src="@/assets/images/elements/change password.png" alt="Mobile">
        </div>
        <h5>تغيير كلمة المرور</h5>
        <p>قم بإدخال كلمة مرور جديدة ثم قم بتأكيدها مرة اخري، لنتمكن من إنشاء كلمة مرور جديدة لك</p>
        <div class="confirm-input">
            <label>كلمة المرور الجديدة</label>
            <div class="inner-confirm-input">
                <svg @click="showPassword =! showPassword" xmlns="http://www.w3.org/2000/svg" width="22.172" height="19" viewBox="0 0 22.172 19">
                    <path id="Icon_ionic-md-eye-off" data-name="Icon ionic-md-eye-off" d="M13.338,8.5a5.026,5.026,0,0,1,5.042,5,4.812,4.812,0,0,1-.361,1.831l2.944,2.919a11.828,11.828,0,0,0,3.459-4.75A11.94,11.94,0,0,0,9.316,6.7l2.177,2.162A4.985,4.985,0,0,1,13.338,8.5ZM3.259,5.772l2.3,2.281.465.46A11.772,11.772,0,0,0,2.25,13.5a11.958,11.958,0,0,0,15.5,6.66l.426.421L21.131,23.5l1.282-1.272L4.536,4.5ZM8.831,11.3l1.564,1.549a2.834,2.834,0,0,0-.079.648,3.008,3.008,0,0,0,3.023,3,2.844,2.844,0,0,0,.653-.079l1.564,1.549A5.016,5.016,0,0,1,8.3,13.49,4.942,4.942,0,0,1,8.831,11.3Zm4.344-.777,3.177,3.152.02-.158a3.008,3.008,0,0,0-3.023-3Z" transform="translate(-2.25 -4.5)" fill="#848484"/>
                </svg>
                <input v-model="setPassword.password" :type="showPassword ? 'text' : 'password'" placeholder="كلمة المرور الجديدة">
            </div>
            <label>تأكيد كلمة المرور الجديدة</label>
            <div class="inner-confirm-input">
                <svg @click="showpasswordConfirmation =! showpasswordConfirmation" xmlns="http://www.w3.org/2000/svg" width="22.172" height="19" viewBox="0 0 22.172 19">
                    <path id="Icon_ionic-md-eye-off" data-name="Icon ionic-md-eye-off" d="M13.338,8.5a5.026,5.026,0,0,1,5.042,5,4.812,4.812,0,0,1-.361,1.831l2.944,2.919a11.828,11.828,0,0,0,3.459-4.75A11.94,11.94,0,0,0,9.316,6.7l2.177,2.162A4.985,4.985,0,0,1,13.338,8.5ZM3.259,5.772l2.3,2.281.465.46A11.772,11.772,0,0,0,2.25,13.5a11.958,11.958,0,0,0,15.5,6.66l.426.421L21.131,23.5l1.282-1.272L4.536,4.5ZM8.831,11.3l1.564,1.549a2.834,2.834,0,0,0-.079.648,3.008,3.008,0,0,0,3.023,3,2.844,2.844,0,0,0,.653-.079l1.564,1.549A5.016,5.016,0,0,1,8.3,13.49,4.942,4.942,0,0,1,8.831,11.3Zm4.344-.777,3.177,3.152.02-.158a3.008,3.008,0,0,0-3.023-3Z" transform="translate(-2.25 -4.5)" fill="#848484"/>
                </svg>
                <input v-model="setPassword.passwordConfirmation" :type="showpasswordConfirmation ? 'text' : 'password'" placeholder="تأكيد كلمة المرور الجديدة">
            </div>
            <span class="err-msg">{{ error_msg }}</span>
            <button @click="changePassword()">{{ loading ? "جاري التغيير" : "تغيير كلمة المرور" }}</button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data () {
        return {
            setPassword: {
                password: '',
                passwordConfirmation: ''
            },
            showPassword: false,
            showpasswordConfirmation: false,
            loading: false
        }
    },
    computed: {
        ...mapGetters("auth", ["code", "success", "error_msg"])
    },
    methods: {
        ...mapActions("auth", ["resetPassword"]),
        async changePassword() {
            this.loading = true
            this.setPassword.code = this.code
            await this.resetPassword(this.setPassword)
            if (this.success){
                this.$router.push('/changed-password').catch(() => {})
            }
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_login.scss';
</style>